import React from 'react';
import dayjs from 'dayjs';
import Avatar from 'seedly-component-library/avatar-new';
import { markdownToPlainText } from 'utils/markdownFormat';
import { convertDateFormat } from 'utils/convertDateFormat';
import clsx from 'clsx';
import { NOTIFICATION_SUBHEADER } from './constants';

const Body = ({ notification, theme }) => {
  const {
    header,
    content,
    thumbnail,
    confirmed,
    lastUpdatedAt,
    type,
    category,
  } = notification;
  const today = dayjs();
  const isGeneralNotice = category === 'notice' && type !== 'deleted_by';

  const head = !isGeneralNotice
    ? NOTIFICATION_SUBHEADER[category][type]
    : header;

  return (
    <>
      <div
        className={clsx(
          'absolute lg:w-2 lg:h-2 w-1.5 h-1.5 ml-2 left-1 bg-blue-500 rounded-full',
          !confirmed ? 'visible' : 'hidden',
        )}
      />
      <div className="mr-2">
        <Avatar sizeClass="avatar-10" src={thumbnail} name={header} />
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-xs font-bold">{head}</p>
        <p
          className={clsx(
            'overflow-hidden text-ellipsis text-xs break-words',
            theme === 'mobile' && 'max-w-[100%] max-h-[51px]',
            theme === 'default' && 'max-w-[290px] max-h-[51px]',
          )}
        >
          {!isGeneralNotice && header} {markdownToPlainText(content)}
        </p>
        <p className="text-xs text-neutral-500">
          {convertDateFormat(today, lastUpdatedAt, ' ')}
        </p>
      </div>
    </>
  );
};

export default Body;
