import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

export const formatDateString = date => {
  dayjs.extend(updateLocale);
  dayjs.extend(relativeTime);

  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'few seconds',
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1mo',
      MM: '%dmo',
      y: '1yr',
      yy: '%dyr',
    },
  });

  const dateMonthDiff = dayjs().diff(date, 'day', true);

  // check if date is more than a month from today, if so, return exact date
  if (Math.round(dateMonthDiff) > 25) {
    return dayjs(date).format('DD MMM YYYY');
  }

  return dayjs(date).fromNow();
};

export const convertDateFormat = (createdAt, updatedAt, preprendText) => {
  let date = '';

  const postEditedFeatureDate = dayjs('2021-07-21'); // Only enabled for post after this date

  const isNotEdited = dayjs(createdAt).isSame(dayjs(updatedAt));

  if (isNotEdited) {
    if (createdAt) {
      date = preprendText
        ? `${preprendText} ${formatDateString(createdAt)}`
        : `${formatDateString(createdAt)}`;
    } else {
      date = preprendText
        ? `${preprendText} on ${formatDateString(createdAt)}`
        : `${formatDateString(createdAt)}`;
    }
    return date;
  }

  if (updatedAt) {
    if (dayjs(updatedAt).isAfter(postEditedFeatureDate)) {
      date = `${preprendText || `Edited`} ${formatDateString(updatedAt)}`;
    } else {
      date = `${formatDateString(updatedAt)}`;
    }
  } else {
    date = `${preprendText || `Updated on`} ${formatDateString(updatedAt)}`;
  }

  return date.trim();
};
