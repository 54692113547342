export const AVAILABLE_THEME = {
  large: {
    lineSpace: 6,
    verticalPadding: 16,
    horizontalPadding: 24,
    markSize: 6,
    markSpace: 10,
  },
  mobile: {
    lineSpace: 18,
    maxWidth: '100%',
    maxHeight: '51px',
    verticalPadding: 0,
    horizontalPadding: 0,
    markSize: 4,
    markSpace: 6,
  },
  default: {
    lineSpace: 18,
    maxWidth: '290px',
    maxHeight: '51px',
    verticalPadding: 0,
    horizontalPadding: 0,
    markSize: 4,
    markSpace: 6,
  },
};

export const DELETED_BY = 'deleted_by';
export const COMMENTED_BY = 'commented_by';

export const NOTIFICATION_SUBHEADER = {
  comment: {
    commented_by: 'New Replies',
    upvoted_by: 'Upvotes on Comment',
    deleted_by: 'Post Deleted',
  },
  review: {
    commented_by: 'New Comments',
    upvoted_by: 'Upvotes on Review',
    deleted_by: 'Post Deleted',
    posted: 'New Comments',
    posted_in_anger: 'Post Deleted',
  },
  opinion: {
    commented_by: 'New Comments',
    upvoted_by: 'Upvotes on Opinion',
    deleted_by: 'Post Deleted',
    posted: 'New Comments',
  },
  question: {
    commented_by: 'New Replies',
    answered_by: 'New Replies',
    upvoted_by: 'Upvotes on Post',
    deleted_by: 'Post Deleted',
    posted: 'New Replies',
  },
  article: {
    commented_by: 'New Replies',
  },
  answer: {
    commented_by: 'New Replies',
    upvoted_by: 'Upvotes on Reply',
    deleted_by: 'Post Deleted',
  },
  notice: {
    deleted_by: 'Post Deleted',
  },
};

export const NOTIFICATION_STATE = {
  meta: {
    pagination: {
      per: 0,
      currentPage: 1,
      totalPage: 1,
      totalCount: 1,
    },
    checkedAt: '',
    newCount: 0,
    unreadCount: 0,
  },
  data: [],
};
