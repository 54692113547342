import { useState, useCallback } from 'react';
import * as Sentry from '@sentry/nextjs';
import notificationApi from 'entity/notification/notificationApi';
import { NOTIFICATION_STATE } from 'app/components/misc/Notification/constants';

function useGetNotifications() {
  const [isFetching, setIsFetching] = useState(false);
  const [notification, setNotifications] = useState(NOTIFICATION_STATE);
  const [currentPage, setPage] = useState({ page: 1, from: 'web' });

  const filterNotifications = useCallback(
    type => {
      const filtered = notification.data.filter(
        notif => notif.tabName === type,
      );

      if (filtered.length > 0) {
        return {
          data: filtered,
        };
      }

      return NOTIFICATION_STATE;
    },
    [notification],
  );

  const fetchNotification = useCallback(
    async tab => {
      setIsFetching(true);
      try {
        const { data: response } = await notificationApi.getNotifications({
          ...currentPage,
          tab,
        });
        const { data, meta } = response;
        setNotifications({
          meta,
          data,
        });
        await notificationApi.checkNotification();
      } catch (error) {
        Sentry.captureException(`Failed to retrieve notifications: ${error}`);
      } finally {
        setIsFetching(false);
      }
    },
    [currentPage, currentPage.page],
  );

  return {
    fetchNotification,
    notification,
    setPage,
    isFetching,
    filterNotifications,
    setNotifications,
  };
}

export default useGetNotifications;
