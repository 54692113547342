import React from 'react';
import Button from 'app/seedly-component-library/button-new';

function Tabs({ currentTab, handleTabClick }) {
  return (
    <>
      <Button
        variant={currentTab === 'All' ? 'outlineGreyHighlight' : 'outlineGrey'}
        size="sm"
        onClick={() => handleTabClick('All')}
        data-testid="notif-all-btn"
      >
        All
      </Button>
      <Button
        variant={
          currentTab === 'Activity' ? 'outlineGreyHighlight' : 'outlineGrey'
        }
        size="sm"
        onClick={() => handleTabClick('Activity')}
        data-testid="notif-activity-btn"
      >
        Activity
      </Button>
      <Button
        variant={
          currentTab === 'Updates' ? 'outlineGreyHighlight' : 'outlineGrey'
        }
        size="sm"
        onClick={() => handleTabClick('Updates')}
        data-testid="notif-updates-btn"
      >
        Updates
      </Button>
    </>
  );
}

export default Tabs;
