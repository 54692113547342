import React from 'react';
import { Bell } from 'react-feather';
import Button from 'seedly-component-library/button-new';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import Link from 'components/misc/Link';

function Empty() {
  return (
    <div className="flex flex-col justify-center items-center gap-4 p-4">
      <Bell size={40} />
      <div className="flex flex-col gap-2 justify-center">
        <p className="text-center font-bold text-sm text-neutral-500">
          You don’t have any notifications now
        </p>
        <p className="text-center">
          Discover content to get updates on the groups that you&apos;re
          interested in.
        </p>
      </div>
      <Link href="/community?discover=true">
        <a
          onClick={() =>
            mixpanel.track(trackedEvents.NotificationClicked, {
              param: 'discover_community',
            })
          }
        >
          <Button>Discover Community</Button>
        </a>
      </Link>
    </div>
  );
}

export default Empty;
