import React from 'react';
import { useDispatch } from 'react-redux';
import Link from 'components/misc/Link';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import {
  openDeletedPostNotifModal,
  openDailyCheckInRewardModal,
} from 'dispatcher/modalDispatcher';
import notificationApi from 'entity/notification/notificationApi';
import { DELETED_BY, COMMENTED_BY } from './constants';
import Body from './Body';

const Item = ({ notification, theme = 'default' }) => {
  const dispatch = useDispatch();

  const confirm = () => {
    mixpanel.track(trackedEvents.NotificationClicked, {
      param: notification.id,
    });
    if (notification.type === DELETED_BY) {
      dispatch(
        openDeletedPostNotifModal({
          message: notification.content,
          post: notification.target.content,
        }),
      );
    }

    if (notification.type === 'daily_check_in') {
      dispatch(openDailyCheckInRewardModal());
    }

    if (notification.confirmed) return;

    notificationApi.confirmNotification(notification.id);
  };

  const { target } = notification;
  let link = target.link;
  if (
    notification.category === 'question' &&
    notification.type === COMMENTED_BY
  ) {
    link = target.link.includes('?')
      ? `${target.link}&showComment=true`
      : `${target.link}?showComment=true`;
  }

  // handle article link
  if (
    link &&
    link.indexOf('http') !== 0 &&
    notification.category === 'article'
  ) {
    link = `/blog/${link}`;
  }

  return (
    <>
      {notification.type === DELETED_BY ||
      notification.type === 'daily_check_in' ? (
        <div
          className="flex w-full py-2 px-6 cursor-pointer bg-white items-center relative"
          onClick={confirm}
          data-testid="notification-item"
        >
          <Body notification={notification} theme={theme} />
        </div>
        ) : (
        <Link href={link} passHref>
          <a>
            <div
              className="flex w-full py-2 px-6 cursor-pointer bg-white items-center relative"
              onClick={confirm}
              data-testid="notification-item"
            >
              <Body notification={notification} theme={theme} />
            </div>
          </a>
        </Link>
        )}
    </>
  );
};

export default Item;
