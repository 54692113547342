import React from 'react';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import Link from 'app/components/misc/Link';

const Footer = props => {
  if (!props.visible) return null;
  if (!props.onClick) {
    return (
      <Link href="/notifications">
        <div
          className="py-4 border-t border-neutral-400 text-blue-500 flex justify-center cursor-pointer"
          onClick={() => {
            mixpanel.track(trackedEvents.NotificationClicked, {
              param: 'see_all',
            });
          }}
        >
          {props.children}
        </div>
      </Link>
    );
  }

  return (
    <div
      className="py-4 border-t border-neutral-400 text-blue-500 flex justify-center cursor-pointer"
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

export default Footer;
