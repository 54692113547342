import React from 'react';

interface Props {
  subTitle: string;
}

const SubHeader = (props: Props) => {
  return (
    <div className="px-6 py-2 overflow-x-auto">
      <p className="text-neutral-500 font-bold text-xs">{props.subTitle}</p>
    </div>
  );
};

export default SubHeader;
